import { applySnapshot, flow, types } from "mobx-state-tree";
import { api } from "../service/api";
import { AppOnboardingModel, UserProfileModel } from "../models/MyClients";
import { IMyClientsParams } from "../constants/interfaces";
import { serviceCoach } from "../utils/env";

const ProfileStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    userProfile: types.maybe(UserProfileModel),
    appOnboarding: types.maybe(AppOnboardingModel),
  })
  .views((self) => ({}))
  .actions((self) => ({
    buildUrl(params: IMyClientsParams) {
      const url = `${serviceCoach}/api/v1/my-clients`;

      const queryParams = new URLSearchParams();

      Object.keys(params).forEach((key) => {
        const value = params[key as keyof IMyClientsParams];
        if (value !== undefined && value !== null) {
          queryParams.append(key, value.toString());
        }
      });

      return `${url}?${queryParams.toString()}`;
    },
  }))
  .actions((self) => ({
    fetchUserProfile: flow(function* (id: number) {
      try {
        self.isLoading = true;
        const { data } = yield api.get(`${serviceCoach}/api/v1/my-clients/user-profile/${id}`);
        const { appOnboarding, userProfile } = data;

        applySnapshot(self, {
          ...self,
          userProfile: userProfile,
          appOnboarding: appOnboarding,
        });
      } catch (error) {
        console.log("Error fetch user profile and app onboarding:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    clear: function () {
      applySnapshot(self, {
        ...self,
        userProfile: undefined,
        appOnboarding: undefined,
      });
    },
  }));

export const profileStore = ProfileStore.create();
