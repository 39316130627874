export const colors = {
  //TODO: Esto sería una posibilidad si necesitaríamos muchos colores primarios
  //   primarys: {
  //     one: "rgba(248, 253, 253, 1)",
  //     two: "rgba(242, 250, 250, 1)",
  //     three: "rgba(229, 245, 245, 1)",
  //   },
  blue: {
    one: "rgba(0, 87, 178, 1)",
    two: "rgba(30, 136, 229, 1)",
  },
  aqua: {
    one: "rgba(227, 244, 244, 1)",
    two: "rgba(184, 227, 227, 1)",
    three: "rgba(138, 210, 209, 1)",
    four: "rgba(92, 192, 189, 1)",
    five: "rgba(57, 177, 173, 1)",
    six: "rgba(36, 163, 157, 1)",
    seven: "rgba(34, 149, 143, 1)",
    eight: "rgba(28, 133, 126, 1)",
    nine: "rgba(28, 117, 110, 1)",
    ten: "rgba(20, 88, 80, 1)",
  },
  background: {
    main: "rgba(237, 241, 243, 1)",
    chip: "rgba(227, 244, 244, 1)",
    icon: "rgba(99, 107, 107, 1)",
    iconError: "rgba(87, 18, 18, 1)",
  },
  black: "rgba(29, 31, 31, 1)",
  white: "rgba(255, 255, 255, 1)",
  error: "rgba(211, 47, 47, 1)",
  //TODO: Esto sería una posibilidad si necesitaríamos mas color para errores
  // error: {
  //   one: "rgba(255, 227, 227, 1)",
  //   two: "rgba(167, 49, 49, 1)",
  //   three: "rgba(233, 22, 22, 1)",
  // },
};
