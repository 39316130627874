import React from "react";
import { Navigate } from "react-router-dom";
import { ROUTE_PATHS } from "../constants/routePaths";
import { useStore } from "../store/StoreContext";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { authStore } = useStore();

  if (!authStore.isAuthenticated()) {
    return <Navigate to={ROUTE_PATHS.SIGN_IN} replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
