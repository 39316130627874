import useLayout from "../hooks/useLayout";
import Drawer from "./Drawer/Drawer";
import "./index.scss";

const withLayOut = ({ children }) => {
  return <Drawer>{children}</Drawer>;
};

const Layout = ({ children }) => {
  const [inAuthLayout] = useLayout();
  return inAuthLayout ? children : withLayOut({ children });
};

export default Layout;
