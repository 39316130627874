import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

export type MenuItem = {
  name: string;
  path: string;
  icon: React.ElementType;
  id: string;
};

export type MenuGroups = {
  group1: MenuItem[];
  group2: MenuItem[];
  group3: MenuItem[];
};

export const MENU_ITEMS: MenuGroups = {
  group1: [
    {
      name: "Home",
      path: "/home",
      icon: HomeOutlinedIcon,
      id: "home-menu-btn",
    },
    {
      name: "My Leads",
      path: "/my-leads",
      icon: FilterListOutlinedIcon,
      id: "leads-menu-btn",
    },
    {
      name: "My Clients",
      path: "/my-clients",
      icon: PeopleAltOutlinedIcon,
      id: "clients-menu-btn",
    },
    {
      name: "Energy Chart",
      path: "/energy-chart",
      icon: DataThresholdingOutlinedIcon,
      id: "energy-menu-btn",
    },
  ],
  group2: [
    {
      name: "Back Office",
      path: "/pap",
      icon: SwapHorizontalCircleOutlinedIcon,
      id: "pap-menu-btn",
    },
    {
      name: "Training Portal",
      path: "/training-portal",
      icon: PlayCircleFilledWhiteOutlinedIcon,
      id: "training-menu-btn",
    },
  ],
  group3: [
    {
      name: "Sign Out",
      path: "/sign-out",
      icon: LogoutOutlinedIcon,
      id: "signout-menu-btn",
    },
  ],
};
