import React from "react";
import MainCard from "../../components/MainCard/MainCard";
import { Button, Typography, List, ListItemText, Box } from "@mui/material";

const PAP = () => {
  return (
    <MainCard alignItems="center">
      <Box alignItems="center" display="flex" flexDirection="column" width={"60%"} id="pap-text">
        <Typography variant="h3" marginBottom={"3rem"} id="pap-heading">
          Back Office
        </Typography>
        <Box alignItems="flex-start" display="flex" flexDirection="column">
          <Typography variant="body1">You can sign in to your Back Office to access these resources:</Typography>
          <List sx={{ listStyleType: "disc", marginLeft: "2rem" }}>
            <ListItemText sx={{ display: "list-item" }}>Your commissions earned and paid</ListItemText>
            <ListItemText sx={{ display: "list-item" }}>
              Your personal links for new clients, lead magnets, and micro-commitments
            </ListItemText>
          </List>
          <Typography variant="body1">
            You have a separate username and password for PAP. It may not be the same as your Coach Connect sign in.
            Contact us if you need help.
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{ marginTop: "3rem" }}
          id="pap-btn-visit"
          href="https://fasterwaybackoffice.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}>Visit Back Office</Typography>
        </Button>
      </Box>
    </MainCard>
  );
};

export default PAP;
