import qs from "qs";
import { apiUrl } from "../utils/env";

interface AuthValue {
  accessToken?: string;
  accessTokenType?: string;
  accessTokenExpiresIn: number;
  refreshToken?: string;
  refreshTokenExpiresIn: number;
}

const API_URL = apiUrl;
const UNAUTHORIZED_ERROR_CODE = 401;
let authHeader: { [key: string]: string } = {};

let _subscribers: Array<(error?: any, tokenData?: AuthValue) => void> = [];

const _subscribe = (subscriber: (error?: any, tokenData?: AuthValue) => void): void => {
  if (!_subscribers.includes(subscriber)) {
    _subscribers.push(subscriber);
  }
};

async function makeRequest(path: string, options: RequestInit, headers: Record<string, string>) {
  const url = `${API_URL}${path}`;
  let wwwAuthenticate: string | null = null;

  try {
    const response = await fetch(url, {
      headers: {
        Accept: "application/json",
        ...headers,
        ...authHeader,
      },
      ...options,
    })
      .then(async (response: any) => {
        wwwAuthenticate = response.headers.get("WWW-Authenticate");
        const responseText = await response.text();
        const JsonpMatch = responseText.match(/\?\((.*)\);/);
        return {
          jsonResponse: JsonpMatch ? JSON.parse(JsonpMatch[1]) : responseText ? JSON.parse(responseText) : undefined,
          statusCode: response.status,
        };
      })
      .catch((error: any) => {
        throw new NetworkApiError({
          message: "Something went wrong. Try again later.",
          data: error,
        });
      });
    const { jsonResponse, statusCode } = response!;

    if (jsonResponse?.success === false) {
      const error = {
        name: jsonResponse?.code,
        message: jsonResponse?.message,
        data: jsonResponse?.codeType,
        details: jsonResponse?.details,
      };

      throw new NetworkApiError(error);
    } else {
      if (statusCode === UNAUTHORIZED_ERROR_CODE) {
        throw new UnauthorizedError({ name: wwwAuthenticate });
      }
    }
    return jsonResponse;
  } catch (error) {
    throw new UnauthorizedError(error);
  }
}

class NetworkApiError extends Error {
  data: any = {};
  details: any = {};

  constructor({ message, data, name, details }: any) {
    super(message);
    this.data = data;
    this.name = name;
    this.details = details;
  }
}

class UnauthorizedError extends NetworkApiError {}

export function assignAuth(auth?: AuthValue) {
  if (auth?.accessTokenType && auth?.accessToken) {
    authHeader = {
      Authorization: `${auth.accessTokenType} ${auth.accessToken}`,
    };
  } else {
    authHeader = {};
  }
}

let onRefresh: (() => Promise<AuthValue>) | null = null;

export function assignOnRefresh(handleRefresh: () => Promise<AuthValue>) {
  onRefresh = handleRefresh;
}

let _isRefreshingToken = false;

const _broadcast = ({ error = null, tokenData }: { error?: any; tokenData?: AuthValue }): void => {
  _isRefreshingToken = false;
  _subscribers.forEach((subscriber) => subscriber(error, tokenData));
  _subscribers = [];
};

const _refreshTokens = async (): Promise<AuthValue> => {
  if (_isRefreshingToken) {
    return new Promise((resolve, reject) => {
      _subscribe((error, tokenData) => {
        if (error) return reject(error);
        resolve(tokenData as AuthValue);
      });
    });
  }

  _isRefreshingToken = true;

  try {
    const tokenData = await onRefresh!();
    _broadcast({ tokenData });
    return tokenData;
  } catch (error) {
    _broadcast({ error });
    throw error;
  }
};

async function request(path: string, options: RequestInit = {}, headers: Record<string, string> = {}): Promise<any> {
  try {
    return await makeRequest(path, options, headers);
  } catch (error) {
    if (error instanceof UnauthorizedError && error?.name?.includes("Bearer")) {
      await _refreshTokens();
      return makeRequest(path, options, headers);
    }
    throw error;
  }
}

const api = {
  get: async (path: string, params?: Object, options: RequestInit = {}): Promise<any> => {
    const query = params ? qs.stringify(params, { addQueryPrefix: true, arrayFormat: "comma" }) : "";
    return request(`${path}${query}`, options, { "Content-Type": "application/json" });
  },
  post: async (path: string, body: Object, options: RequestInit = {}): Promise<any> => {
    return request(
      path,
      { method: "POST", body: JSON.stringify(body), ...options },
      { "Content-Type": "application/json" }
    );
  },
  put: async (path: string, body: Object, options: RequestInit = {}): Promise<any> => {
    return request(
      path,
      { method: "PUT", body: JSON.stringify(body), ...options },
      { "Content-Type": "application/json" }
    );
  },
  delete: async (path: string, params?: Object, options: RequestInit = {}): Promise<any> => {
    const query = params ? qs.stringify(params, { addQueryPrefix: true, arrayFormat: "repeat" }) : "";
    return request(`${path}${query}`, { method: "DELETE", ...options }, { "Content-Type": "application/json" });
  },
  patch: async (path: string, body: Object, options: RequestInit = {}): Promise<any> => {
    return request(
      path,
      { method: "PATCH", body: JSON.stringify(body), ...options },
      { "Content-Type": "application/json" }
    );
  },
};

export { api, UnauthorizedError, NetworkApiError };
