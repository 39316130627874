import { Alert, Snackbar as SnackbarMUI, SnackbarProps, AlertProps } from "@mui/material";

type CustomSnackbarProps = SnackbarProps & AlertProps;

const Snackbar = ({
  message,
  severity,
  open,
  onClose,
  autoHideDuration = 4000,
  anchorOrigin = { vertical: "top", horizontal: "right" },
  sx = {
    "& .MuiAlert-root": {
      fontSize: "1.25rem",
    },
  },
  ...snackbarProps
}: CustomSnackbarProps) => {
  return (
    <SnackbarMUI
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      sx={sx}
      {...snackbarProps}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </SnackbarMUI>
  );
};

export default Snackbar;
