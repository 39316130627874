import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { default as DrawerMUI } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { MENU_ITEMS, MenuGroups } from "../../constants/menu";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import IFWIcon from "../../assets/svg/IFWIcon";
import IFWLogo from "../../assets/svg/IFWLogo";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/routePaths";
import { Grid, Theme } from "@mui/material";
import { useStore } from "../../store/StoreContext";
import "./style.scss";
import { homeStore } from "../../store/HomeStore";
import Spinner from "../../components/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 240;

interface Props {
  window?: () => Window;
  children: React.ReactNode;
  menuItems?: typeof MENU_ITEMS;
}

const Drawer = observer((props: Props) => {
  const { window, children, menuItems = MENU_ITEMS } = props;
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [activeNavItem, setActiveNavItem] = useState(ROUTE_PATHS.HOME);
  const location = useLocation();
  const { authStore } = useStore();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  let navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = (path: string) => {
    if (path === ROUTE_PATHS.SIGN_OUT) {
      homeStore.clear();
      authStore.logout();
      navigate(ROUTE_PATHS.SIGN_IN);
    } else navigate(path);
  };

  const handleClickHelpPage = () => {
    navigate(ROUTE_PATHS.HELP);
  };

  useEffect(() => {
    homeStore.coach?.fullName && setUserName(homeStore.coach?.fullName ?? "");
  }, []);

  useEffect(() => {
    homeStore.coach?.fullName && setUserName(homeStore.coach?.fullName ?? "");
  }, [homeStore.coach]);

  useEffect(() => {
    setActiveNavItem(location.pathname);
  }, [location.pathname]);

  const drawer = (
    <div>
      <Toolbar onClick={() => navigate(ROUTE_PATHS.HOME)} sx={{ cursor: "pointer" }}>
        <IFWIcon />
        <Typography
          variant="h6"
          noWrap
          component="a"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            color: "inherit",
            textDecoration: "none",
            fontSize: "1.6rem",
            marginLeft: "1.5rem",
          }}
        >
          CoachConnect
        </Typography>
      </Toolbar>
      <Divider />
      {Object.keys(menuItems).map((group, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <List>
            {menuItems[group as keyof MenuGroups].map((item, index) => (
              <ListItem key={item.name}>
                <ListItemButton
                  id={item.id}
                  onClick={() => {
                    handleMenuItemClick(item.path);
                    setActiveNavItem(item.path);
                  }}
                  sx={{
                    ...(location.pathname.startsWith(item.path) && {
                      backgroundColor: "#8AD2D13D",
                      borderRadius: "8px",
                    }),
                    "&:hover": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <ListItemIcon>
                    <item.icon sx={{ fontSize: "2.4rem" }} />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {groupIndex < Object.keys(menuItems).length - 1 && <Divider variant="middle" />}
        </React.Fragment>
      ))}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", backgroundColor: "#EDF1F3", minHeight: "100vh", overflow:"hidden" }}>
      <CssBaseline />
      <AppBar
        color="inherit"
        position="fixed"
        sx={{ width: { lg: `calc(100% - ${drawerWidth}px)` }, ml: { lg: `${drawerWidth}px` } }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuOutlinedIcon />
          </IconButton>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              {userName ? (
                <Typography
                  variant="body1"
                  noWrap
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: { xs: "none", sm: "block" },
                    minHeight: "1em",
                  }}
                >
                  {`Hello, ${userName}!`}
                </Typography>
              ) : (
                <div className="container-spinner">
                  <Spinner size={20} />
                </div>
              )}
            </Grid>
            <Grid item xs={4} container justifyContent="center">
              <Box>
                {isMobile ? (
                  <>
                    <Box sx={{ alignItems: "center", display: "flex" }}>
                      <IFWIcon />
                      <Typography
                        variant="h6"
                        noWrap
                        component="span"
                        sx={{
                          marginLeft: "1rem",
                          color: "inherit",
                          textDecoration: "none",
                          fontSize: "1.6rem",
                          marginTop: "0.5rem",
                        }}
                      >
                        CoachConnect
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <IFWLogo />
                )}
              </Box>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end">
              <IconButton onClick={handleClickHelpPage} id="home-btn-help">
                <HelpOutlineOutlinedIcon sx={{ fontSize: "2.4rem", color: "#0000008F" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }} aria-label="mailbox folders">
        <DrawerMUI
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </DrawerMUI>
        <DrawerMUI
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
          open
        >
          {drawer}
        </DrawerMUI>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "72px",
          padding: { xs: "72px 2px 0px 14px", sm: "72px 2px 0px 14px" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
});

export default Drawer;
