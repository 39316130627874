import React from "react";
import MainCard from "../../components/MainCard/MainCard";
import { Box, Link, Typography } from "@mui/material";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/routePaths";
import icon from "../../assets/images/icon-f.png";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <MainCard alignItems="center" justifyContent="center">
      <Box display="flex" justifyContent="center" position="relative" width="100%" height="100%">
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${icon})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 0.1,
            zIndex: 1,
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "relative",
            zIndex: 2,
            padding: "5rem",
          }}
        >
          <Typography sx={{ fontSize: "10rem", lineHeight: "12rem", fontWeight: 700, color: colors.aqua.three }}>
            Oops!
          </Typography>
          <Typography sx={{ fontSize: "3rem", lineHeight: "5rem", fontWeight: 300 }}>Page not found</Typography>
          <Typography>It looks like the page you are looking for does not exist or has been moved.</Typography>
          <Typography>
            Don't worry, back to
            <Link sx={{ marginLeft: "0.6rem" }} onClick={() => navigate(ROUTE_PATHS.HOME)}>
              home
            </Link>
          </Typography>
        </Box>
      </Box>
    </MainCard>
  );
};

export default PageNotFound;
