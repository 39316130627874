import { types } from "mobx-state-tree";


//MY CLIENT - TABLE
export const MyClientsModel = types.model(" MyClientsModel", {
  id: types.number,
  fullName: types.string,
  activeProgram: types.optional(types.string, "-"),
  programStartDate: types.optional(types.string, "-"),
  // lastLoginDate: types.optional(types.string, "-"),
  // loginsPast30Days: types.optional(types.number, 0),
  // status: types.string,
  phone: types.maybeNull(types.string),
  dateOfBirth: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
});

// COAC-41 - active programs
export const ActiveProgramsModel = types.model("ActiveProgramsModel", {
  id: types.number,
  name: types.string,
  value: types.string,
});

// COAC-56 - search
export const SearchModel = types.model("SearchModel", {
  label: types.string,
  value: types.string,
});

//MY CLIENT - PROFILE

// COAC-45 - model macro plan

const FoodDayTypeModel = types.model("FoodDayTypeModel", {
  name: types.optional(types.string, "-"),
  id: types.optional(types.number, 0),
});

export const ItemModelMacroPlan = types.model("ItemModel", {
  foodDayType: types.maybe(FoodDayTypeModel),
  carbs: types.maybe(types.number),
  fat: types.maybe(types.number),
  protein: types.maybe(types.number),
  unit: types.maybe(types.string),
});

export const selectedMacroPlanModel = types.model("selectedMacroPlanModel", {
  carbs: types.optional(types.number, 0),
  carbsMin: types.maybe(types.number),
  created: types.maybe(types.string),
  fat: types.optional(types.number, 0),
  fatMin: types.maybe(types.number),
  id: types.maybe(types.number),
  lowCarbCoefficient: types.optional(types.number, 0),
  protein: types.optional(types.number, 0),
  proteinMin: types.maybe(types.number),
  standard: types.maybe(types.boolean),
  title: types.maybe(types.string),
  updated: types.maybe(types.string),
});

// // COAC-44 - profile
const BadgeUserTotalStartModel = types.model("BadgeUserTotalStartModel", {
  fileUrl: types.maybe(types.string),
  totalStarts: types.optional(types.number, 0),
});

export const UserProfileModel = types.model("UserProfileModel", {
  fullName: types.optional(types.string, "-"),
  userBlobFileUrl: types.optional(types.string, "-"),
  phone: types.optional(types.string, "-"),
  email: types.string,
  age: types.optional(types.number, 0),
  dateOfBirth: types.optional(types.string, "-"),
  gender: types.optional(types.string, "-"),
  language: types.optional(types.string, "-"),
  height: types.optional(types.string, "-"),
  startingWeight: types.optional(types.string, ""),
  dietType: types.optional(types.string, "-"),
  fastingGoal: types.optional(types.number, 0),
  badgeUserTotalStart: types.optional(BadgeUserTotalStartModel, {}),
});

export const AppOnboardingModel = types.model("AppOnboardingModel", {
  activeProgram: types.optional(types.string, "-"),
  activeProgramStartDate: types.optional(types.string, "-"),
  appOnboardingDate: types.optional(types.string, "-"),
  vipStartDate: types.optional(types.string, "-"),
  lastLoginDate: types.optional(types.string, "-"),
  cancelDate: types.optional(types.string, "-"),
  startingMotivation: types.optional(types.array(types.string), []),
  medicalConditions: types.optional(types.array(types.string), []),
  dailyChallenges: types.optional(types.array(types.string), []),
  activityLevel: types.optional(types.string, "-"),
  exerciseDay: types.optional(types.integer, 0),
  mainGoal: types.optional(types.string, "-"),
});

//MY CLIENT - App Tracking
export const ItemAppTrackingModel = types.model("ItemAppTrackingModel", {
  id: types.optional(types.number, 0),
  date: types.string,
  startsDay: types.integer,
  workoutDay: types.optional(types.boolean, false),
  waterDay: types.optional(types.integer, 0),
  fastingDay: types.optional(types.integer, 0),
  foodDay: types.optional(types.integer, 0),
});

//COAC-47 - food log date
const MacroNutrientsModel = types.model("MacroNutrientsModel", {
  calcium: types.maybe(types.number),
  calories: types.maybe(types.number),
  carbs: types.maybe(types.number),
  cholesterol: types.maybe(types.number),
  fat: types.maybe(types.number),
  fiber: types.maybe(types.number),
  iron: types.maybe(types.number),
  magnesium: types.maybe(types.number),
  potassium: types.maybe(types.number),
  protein: types.maybe(types.number),
  saturatedFat: types.maybe(types.number),
  sodium: types.maybe(types.number),
  sugar: types.maybe(types.number),
  transFat: types.maybe(types.number),
  vitaminA: types.maybe(types.number),
  vitaminC: types.maybe(types.number),
  vitaminD: types.maybe(types.number),
  zinc: types.maybe(types.number),
});

export const FoodLogItemModel = types.model("FoodLogItemModel", {
  amount: types.maybe(types.integer),
  id: types.integer,
  imageUrl: types.maybe(types.string),
  internalId: types.maybe(types.integer),
  itemType: types.maybe(types.string),
  macronutrients: types.maybe(MacroNutrientsModel),
  title: types.maybe(types.string),
});

export const FoodMealTypeModel = types.model("FoodMealTypeModel", {
  id: types.integer,
  name: types.string,
  order: types.integer,
});

export const MealItemModel = types.model("MealItemModel", {
  foodMealType: FoodMealTypeModel,
  items: types.optional(types.array(FoodLogItemModel), []),
});

export const AppTrackingFoodLogObj = types.model("AppTrackingFoodLogObj", {
  items: MealItemModel,
});

export const RowsModel = types.model("ItemMyClientsModel", {
  id: types.integer,
  fullName: types.string,
  activeProgram: types.string,
  programStartDate: types.maybe(types.string),
  // lastLoginDate: types.maybe(types.string),
  // loginsPast30Days: types.maybe(types.number),
  // status: types.string,
  redirect: types.number,
});