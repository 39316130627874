import { flow, types, applySnapshot } from "mobx-state-tree";
import { api } from "../service/api";
import { ItemModelMacroPlan, MealItemModel, selectedMacroPlanModel } from "../models/MyClients";
import { serviceFood } from "../utils/env";
import { convertDateFormat } from "../utils/date";

const FoodStore = types
  .model({
    appTrackingFoodLog: types.array(MealItemModel),
    isLoading: types.optional(types.boolean, true),
    macroPlanItems: types.optional(types.array(ItemModelMacroPlan), []),
    selectedMacroPlan: types.optional(selectedMacroPlanModel, {}),
  })
  .actions((self) => ({
    fetchFood: flow(function* (id: number, date: string) {
      try {
        self.isLoading = true;
        const { data } = yield api.get(
          `${serviceFood}/api/v1/food-log/${convertDateFormat(date)}/my-clients/app-tracking/${id}`
        );
        const { items } = data;
        applySnapshot(self, {
          ...self,
          appTrackingFoodLog: items,
        });
      } catch (error) {
        console.log("Error fetch food app tracking:", error);
      } finally {
        self.isLoading = false;
      }
    }),
    fetchMacroPlan: flow(function* (id: number) {
      try {
        self.isLoading = true;
        const { data } = yield api.get(`${serviceFood}/api/v1/macro-plan/${id}`);

        const { items, selectedMacroPlan } = data;

        applySnapshot(self, {
          macroPlanItems: items,
          selectedMacroPlan: selectedMacroPlan,
        });
      } catch (error) {
        console.log("Error fetch macro plan:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    clear: function () {
      applySnapshot(self, {
        ...self,
        appTrackingFoodLog: [],
        macroPlanItems: [],
        selectedMacroPlan: undefined,
      });
    },
  }));

export const foodStore = FoodStore.create();
