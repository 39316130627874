import React, { useState } from "react";
import Iframe from "../../components/Iframe/Iframe";
import MainCard from "../../components/MainCard/MainCard";
import { Box, Typography } from "@mui/material";
import Spinner from "../../components/Spinner/Spinner";

const EnergyChart = () => {
  const [loading, setLoading] = useState(true);
  const sheetUrl =
    "https://docs.google.com/spreadsheets/d/1QYcd-GInhJ3KkGJPTsN1IPkQCivPIkAPj-z-1iJHpH0/edit?usp=sharing";

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <MainCard>
      <Typography variant="h3" sx={{ alignSelf: "center", marginBottom: "10px" }}>
        Coach Energy Chart
      </Typography>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "inherit" }}>
          <Spinner />
        </Box>
      )}
      <Iframe onLoad={handleLoad} sheetUrl={sheetUrl} style={{ display: loading ? "none" : "block" }} />
    </MainCard>
  );
};

export default EnergyChart;
