import React from "react";

interface GoogleSheetEmbedProps {
  sheetUrl: string;
  onLoad?: () => void;
  style?: React.CSSProperties;
}

const Iframe: React.FC<GoogleSheetEmbedProps> = ({ sheetUrl, onLoad, style }) => {
  const embedUrl = sheetUrl.replace("/edit?usp=sharing", "/preview");

  return (
    <iframe
      style={{ border: "none", ...style }}
      onLoad={onLoad}
      src={embedUrl}
      width="100%"
      height="100%"
      frameBorder="0"
      allowFullScreen
    ></iframe>
  );
};

export default Iframe;
