import React from "react";
import MainCard from "../../components/MainCard/MainCard";
import { Button, Typography, List, ListItemText, Box } from "@mui/material";

const TrainingPortal = () => {
  return (
    <MainCard alignItems="center">
      <Box alignItems="center" display="flex" flexDirection="column" width={"60%"} id="training-portal-text">
        <Typography variant="h3" marginBottom={"3rem"} id="training-portal-heading">
          Training Portal
        </Typography>
        <Box alignItems="flex-start" display="flex" flexDirection="column">
          <Typography variant="body1">
            Our Training Portal has two main sets of resources to help you as a Coach:
          </Typography>
          <List sx={{ listStyleType: "disc", marginLeft: "2rem" }}>
            <ListItemText sx={{ display: "list-item" }}>
              The original curriculum you completed when you first became a coach
            </ListItemText>
            <ListItemText sx={{ display: "list-item" }}>
              References for key FASTer Way concepts and Client education materials
            </ListItemText>
          </List>
          <Typography variant="body1">
            You have a separate username and password for the Training Portal. It may not be the same as your Coach
            Connect sign in. Contact us if you need help.
          </Typography>
        </Box>
        <Button
          id="training-portal-btn-visit"
          variant="contained"
          color="secondary"
          size="large"
          sx={{ marginTop: "3rem" }}
          href="https://www.fasterwayportal.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}>Visit Training Portal</Typography>
        </Button>
      </Box>
    </MainCard>
  );
};

export default TrainingPortal;
