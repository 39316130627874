import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useLayout = () => {
  const { pathname } = useLocation();

  const [inAuth, setInAuth] = useState<true | false>(false);

  // If we are in the some nested route of /auth we will hide the layout elements
  useEffect(() => {
    if (pathname.includes("/signin") || pathname === "/") {
      setInAuth(true);
    } else {
      setInAuth(false);
    }
  }, [pathname]);

  return [inAuth, setInAuth] as const;
};

export default useLayout;
