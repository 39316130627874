import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#1565C0",
      light: "#1E88E5",
      dark: "#0057B2",
      contrastText: "#fff",
    },
    secondary: {
      main: "#C32390",
      light: "#CF54A3",
      dark: "#B50080",
      contrastText: "#fff",
    },
    error: {
      main: "#D32F2F",
      light: "#EF5350",
      dark: "#C62828",
      contrastText: "#fff",
    },
    warning: {
      main: "#FBC02D",
      light: "#FDD835",
      dark: "#F57F17",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#01579B",
      light: "#03A9F4",
      dark: "#01579B",
      contrastText: "#fff",
    },
    success: {
      main: "#2E7D32",
      light: "#4CAF50",
      dark: "#1B5E20",
      contrastText: "#fff",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    divider: "#0000001F",
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    action: {
      active: "#0000008F",
      hover: "#1565C00A",
      hoverOpacity: 0.04,
      selected: "#1565C014",
      selectedOpacity: 0.08,
      disabled: "#00000061",
      disabledBackground: "#0000001F",
      disabledOpacity: 0.38,
      focus: "#1565C01F",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Inter", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Inter", sans-serif',
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: '"Inter", sans-serif',
      fontWeight: 400,
      fontSize: "3.6rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: '"Inter", sans-serif',
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: '"Inter", sans-serif',
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    h6: {
      fontFamily: '"Inter", sans-serif',
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
    },
    body1: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "1.6rem",
      fontWeight: 400,
      lineHeight: "2.4rem",
    },
    body2: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "1.6rem",
      fontWeight: 700,
      lineHeight: "2.4rem",
    },
  },
  shape: {
    borderRadius: 8,
  },
});

export default theme;
