import { applySnapshot, flow, Instance, types } from "mobx-state-tree";
import { ItemMyLeadsModel, RowsModel } from "../models/MyLeads";
import { ContextModel, SearchModel } from "../models/Common";
import { api } from "../service/api";
import { IMyLeadsCSVData, IMyLeadsParam, IParam } from "../constants/interfaces";
import { serviceCoach } from "../utils/env";
import { formatDate } from "../utils/date";

const MyLeadsStore = types
  .model({
    items: types.maybeNull(types.array(ItemMyLeadsModel)),
    rows: types.optional(types.array(RowsModel), []),
    searchItems: types.optional(types.array(SearchModel), []),
    context: types.maybeNull(ContextModel),
    isLoading: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    buildUrl(params: IParam, url: string) {
      const urlDefault = `${serviceCoach}${url}`;
      const queryParams = new URLSearchParams();

      Object.keys(params).forEach((key) => {
        const value = params[key as keyof IParam];
        if (value !== undefined && value !== null) {
          queryParams.append(key, value.toString());
        }
      });

      return `${urlDefault}?${queryParams.toString()}`;
    },
  }))
  .actions((self) => ({
    fetch: flow(function* (params: IParam) {
      try {
        const url = self.buildUrl(params, "/api/v1/my-leads");
        const { data } = yield api.get(url);
        const { context, items } = data;
        let rowsAux: Instance<typeof RowsModel>[] | [] = [];
        self.isLoading = true;

        if (items?.length && items.length > 0) {
          items?.forEach((item: Instance<typeof ItemMyLeadsModel>, index: number) => {
            let row: Instance<typeof RowsModel> = {
              id: 0,
              date: "",
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              source: "",
            };

            row.id = index;
            row.date = item?.date ? formatDate(item.date) : "-";
            row.firstName = item.firstName ?? "-";
            row.lastName = item.lastName ?? "-";
            row.phone = item.phone ?? "-";
            row.email = item.email ?? "-";
            row.source = item.source ?? "-";

            rowsAux = [...rowsAux, row];
          });
        }

        applySnapshot(self, {
          ...self,
          items,
          rows: rowsAux,
          context,
          isLoading: false,
        });
      } catch (error) {
        console.log("Error fetch My Leads:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchCSVData: flow(function* (params: IParam) {
      try {
        const url = self.buildUrl(params, "/api/v1/my-leads");
        const { data } = yield api.get(url);
        const { items } = data;
        let csvData: IMyLeadsCSVData[] | [] = [];
        self.isLoading = true;

        if (items?.length && items.length > 0) {
          items?.forEach((item: Instance<typeof ItemMyLeadsModel>) => {
            let row: IMyLeadsCSVData = {
              date: "",
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              source: "",
            };

            row.date = item?.date ? formatDate(item.date) : "-";
            row.firstName = item.firstName ?? "-";
            row.lastName = item.lastName ?? "-";
            row.phone = item.phone ?? "-";
            row.email = item.email ?? "-";
            row.source = item.source ?? "-";

            csvData = [...csvData, row];
          });
        }
        return csvData;
      } catch (error) {
        console.log("Error fetch My Leads:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    fetchSearch: flow(function* (params: IMyLeadsParam) {
      try {
        self.isLoading = true;
        const url = self.buildUrl(params, "/api/v1/my-leads/search");
        const { data } = yield api.get(url);

        applySnapshot(self, {
          ...self,
          searchItems: data,
        });
      } catch (error) {
        console.log("Error fetchSearch My Leads:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    clear: function* () {
      applySnapshot(self, {
        ...self,
        items: [],
        rows: [],
        context: undefined,
      });
    },
  }));

export const myLeadsStore = MyLeadsStore.create();
