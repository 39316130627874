import React from "react";
import { Box } from "@mui/material";
import { colors } from "../../constants/colors";

interface MainCardProps {
  children: React.ReactNode;
  alignItems?: string;
  backgroundColor?: string;
  justifyContent?: string;
}

const MainCard: React.FC<MainCardProps> = ({
  children,
  alignItems = "normal",
  backgroundColor = colors.white,
  justifyContent,
}) => {
  return (
    <Box
      sx={(theme) => ({
        padding: {
          xs: "0rem", 
          sm: "2rem",
        },
        
        borderRadius: ".8rem",
        backgroundColor: backgroundColor,
        display: "flex",
        flexDirection: "column",
        alignItems: alignItems,
        overflow: "auto",
        maxHeight: "calc(100vh - 7.2rem - 4rem)",
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        justifyContent: justifyContent,
      })}
    >
      {children}
    </Box>
  );
};

export default MainCard;
