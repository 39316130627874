import { Route, Routes } from "react-router-dom";
import { routeArray, routeProps } from "./routeArray";
import PrivateRoute from "./PrivateRoute";
import Layout from "../layout";

const MainRoutes = () => {
  return (
    <Routes>
      {routeArray.map((route: routeProps, i: number) => {
        if (route.private) {
          return (
            <Route
              key={i}
              path={route.path}
              element={
                <PrivateRoute>
                  <Layout>{route.component}</Layout>
                </PrivateRoute>
              }
            />
          );
        }
        return <Route key={i} path={route.path} element={route.component} />;
      })}
    </Routes>
  );
};

export default MainRoutes;
