import { flow, types, applySnapshot, onSnapshot } from "mobx-state-tree";
import { api, assignAuth, assignOnRefresh } from "../service/api";
import { AuthModel } from "../models/Auth";
import { serviceCoach } from "../utils/env";

const AuthStore = types
  .model({
    tokenData: types.maybe(AuthModel),
    isNeedChangePassword: types.maybe(types.boolean),
    isPreviewAccount: types.maybe(types.boolean),
    isLoading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isAuthorized() {
      return !!self.tokenData?.accessToken;
    },
  }))
  .actions((self) => ({
    isAuthenticated(): boolean {
      return self.tokenData && self.tokenData?.accessToken && self.tokenData?.refreshToken ? true : false;
    },
    setTokenData(tokenData: any) {
      self.tokenData = tokenData;
      assignAuth(tokenData);
      window.localStorage.setItem("tokenData", JSON.stringify(tokenData));
    },
    logout() {
      window.localStorage.removeItem("tokenData");
      self.tokenData = undefined;
      assignAuth(undefined);
      applySnapshot(self, {
        ...self,
        tokenData: undefined,
        isNeedChangePassword: undefined,
        isPreviewAccount: undefined,
      });
    },
  }))
  .actions((self) => ({
    refreshToken: flow(function* () {
      try {
        self.isLoading = true;
        const { tokenData } = yield api.post(`${serviceCoach}/api/v1/login/refresh`, {
          refreshToken: self.tokenData?.refreshToken,
        });

        if (tokenData) {
          self.setTokenData(tokenData);
        }

        return tokenData;
      } catch (error) {
        console.log("Error al refrescar token:", error);
        self.logout();
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }))
  .actions((self) => ({
    afterCreate() {
      const tokenData = window.localStorage.getItem("tokenData");
      assignOnRefresh(self.refreshToken);
      if (tokenData) {
        const authObject = JSON.parse(tokenData);
        self.tokenData = authObject;
        assignAuth(authObject);
      }

      onSnapshot(self, ({ tokenData }) => {
        assignAuth(tokenData);
        assignOnRefresh(self.refreshToken);
        if (tokenData) {
          window.localStorage.setItem("tokenData", JSON.stringify(tokenData));
        }
      });
    },
    login: flow(function* (email: string, password: string) {
      try {
        self.isLoading = true;
        const { tokenData, isNeedChangePassword, isPreviewAccount } = yield api.post(`${serviceCoach}/api/v1/login`, {
          email,
          password,
        });

        if (tokenData?.accessToken) {
          applySnapshot(self, {
            ...self,
            tokenData,
            isNeedChangePassword,
            isPreviewAccount,
          });

          self.setTokenData(tokenData);

          if (tokenData?.refreshToken) {
            assignOnRefresh(self.refreshToken);
          }
        }
      } catch (error) {
        console.log("Error en el login:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
  }));

export const authStore = AuthStore.create();
