import { types } from "mobx-state-tree";

export const ItemMyLeadsModel = types.model("ItemMyLeadsModel", { 
  date: types.string,
  firstName: types.optional(types.string, "-"),
  lastName: types.optional(types.string, "-"),
  phone: types.optional(types.string,"-"),
  email: types.string,
  source: types.optional(types.string, "-"),
});
export const RowsModel = types.model("ItemMyLeadsModel", {
  id: types.number,
  date: types.string,
  firstName: types.string,
  lastName: types.string,
  phone: types.string,
  email: types.string,
  source: types.string,
});
