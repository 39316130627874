import React from "react";
import { default as CardMUI } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import "./style.scss";
import { Box } from "@mui/material";

export type CardColor = "primary" | "secondary";

interface MyCardProps {
  title: string;
  primaryText: string | number;
  secondaryText: string;
  onClick: () => void;
  color: CardColor;
  id: string;
  className: string
}

const Card: React.FC<MyCardProps> = ({ title, primaryText, secondaryText, onClick, color, id, className }) => {
  return (
    <CardMUI
      className="card"
      id={id}
      onClick={onClick}
      sx={{
        position: "relative",
        cursor: "pointer",
        minHeight: "180px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "33rem",
      }}
    >
      <CardContent sx={{ height: " -webkit-fill-available" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          height={"100%"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} justifyContent={"space-between"} flexDirection={"row"} width={"100%"}>
            <Typography variant="h6" component="div">
              {title}
            </Typography>
            <ArrowCircleRightOutlinedIcon color="secondary" sx={{ fontSize: "2.4rem" }} />
          </Box>
          <Typography variant="h2" component="div" sx={{ marginTop: 2, marginBottom: 1 }}>
            {primaryText}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 1 }}>
            {secondaryText}
          </Typography>
        </Box>
      </CardContent>
      <div className={`card-color-line ${color}`} />
    </CardMUI>
  );
};

export default Card;
