const IFWLogo = () => {
  return (
    <svg width="141" height="32" viewBox="0 0 141 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.648346 25.6777V26.1364H2.41788V31.8933H3.14418V26.1371H4.92274V25.6784L0.648346 25.6777Z"
        fill="#363435"
      />
      <path
        d="M16.7131 31.9878C15.0395 31.9878 14.0547 30.7736 14.0547 28.7942C14.0547 26.8148 15.0395 25.5908 16.7131 25.5908C18.3867 25.5908 19.3883 26.8252 19.3883 28.7949C19.3883 30.7646 18.3944 31.9885 16.711 31.9885M16.711 26.0509C15.5059 26.0509 14.8268 27.017 14.8268 28.7956C14.8268 30.5741 15.4961 31.5305 16.711 31.5305C17.9259 31.5305 18.6133 30.5839 18.6133 28.7956C18.6133 27.0073 17.9252 26.0509 16.711 26.0509Z"
        fill="#363435"
      />
      <path
        d="M43.2276 29.0562H40.9139V31.8968H40.1869V25.677H43.6008V26.1371H40.9139V28.6128H43.2276V29.0562Z"
        fill="#363435"
      />
      <path
        d="M55.1139 25.6777L57.1698 31.8933H56.4143L55.7637 29.9813H53.3256L52.6751 31.8933H52.1677L54.2625 25.6777H55.1139ZM53.4597 29.5886H55.64L54.56 26.2323L53.4597 29.5886Z"
        fill="#363435"
      />
      <path d="M69.7539 26.1371H67.9753V31.8939H67.2477V26.1371H65.4788V25.6783H69.7539V26.1371Z" fill="#363435" />
      <path d="M93.6481 31.8933H90.1584V25.6777H90.8847V31.4346H93.6481V31.8933Z" fill="#363435" />
      <path
        d="M105.486 31.9878C103.812 31.9878 102.828 30.7736 102.828 28.7942C102.828 26.8148 103.812 25.5908 105.486 25.5908C107.161 25.5908 108.164 26.8245 108.164 28.7942C108.164 30.7639 107.17 31.9878 105.486 31.9878ZM105.486 26.0502C104.281 26.0502 103.602 27.0163 103.602 28.7949C103.602 30.5734 104.272 31.5298 105.486 31.5298C106.701 31.5298 107.389 30.5832 107.389 28.7949C107.389 27.0066 106.701 26.0502 105.486 26.0502Z"
        fill="#363435"
      />
      <path
        d="M121.139 26.5478C120.769 26.2204 120.294 26.0373 119.8 26.0321C119.649 26.0168 119.498 26.0342 119.355 26.0834C119.212 26.1325 119.081 26.2121 118.972 26.3167C118.863 26.4213 118.778 26.5484 118.723 26.6891C118.668 26.8298 118.644 26.9808 118.653 27.1317C118.653 28.7476 121.464 28.0783 121.464 30.1898C121.466 30.4421 121.414 30.6919 121.313 30.923C121.212 31.154 121.063 31.3611 120.876 31.5307C120.689 31.7003 120.468 31.8286 120.229 31.9072C119.989 31.9859 119.735 32.0131 119.484 31.9871C119.134 32.0272 118.779 31.9729 118.456 31.83C118.133 31.687 117.855 31.4606 117.649 31.1739L117.888 30.8021C118.067 31.0444 118.305 31.2373 118.579 31.3626C118.854 31.4879 119.155 31.5416 119.456 31.5187C119.623 31.5342 119.791 31.5141 119.95 31.4596C120.108 31.4051 120.253 31.3175 120.375 31.2026C120.497 31.0877 120.594 30.9481 120.658 30.7931C120.722 30.6381 120.752 30.4713 120.746 30.3038C120.746 28.6976 117.936 29.3196 117.936 27.3013C117.926 27.0639 117.968 26.8273 118.058 26.6075C118.148 26.3877 118.284 26.1899 118.457 26.0275C118.631 25.8651 118.837 25.7419 119.062 25.6663C119.287 25.5907 119.526 25.5645 119.762 25.5894C120.302 25.5824 120.826 25.7727 121.235 26.1246L121.139 26.5478Z"
        fill="#363435"
      />
      <path
        d="M134.431 26.5478C134.061 26.2207 133.586 26.0376 133.092 26.0321C132.942 26.0166 132.79 26.034 132.647 26.0831C132.504 26.1322 132.373 26.2118 132.264 26.3164C132.155 26.421 132.07 26.5481 132.015 26.6889C131.959 26.8297 131.936 26.9807 131.945 27.1316C131.945 28.7476 134.756 28.0783 134.756 30.1898C134.758 30.442 134.706 30.6917 134.604 30.9227C134.503 31.1536 134.354 31.3606 134.167 31.5302C133.981 31.6997 133.76 31.828 133.521 31.9068C133.281 31.9855 133.028 32.0129 132.777 31.9871C132.426 32.0273 132.071 31.9731 131.748 31.8302C131.426 31.6872 131.147 31.4606 130.941 31.1739L131.18 30.8021C131.36 31.0444 131.597 31.2373 131.872 31.3626C132.146 31.4879 132.447 31.5416 132.748 31.5186C132.915 31.5336 133.083 31.5131 133.241 31.4586C133.399 31.404 133.544 31.3165 133.666 31.202C133.788 31.0874 133.884 30.9483 133.948 30.7938C134.012 30.6394 134.043 30.4731 134.039 30.3058C134.039 28.6996 131.227 29.3217 131.227 27.3033C131.218 27.066 131.26 26.8293 131.35 26.6096C131.44 26.3898 131.576 26.1919 131.75 26.0295C131.923 25.8671 132.129 25.7439 132.354 25.6683C132.58 25.5928 132.818 25.5665 133.055 25.5915C133.594 25.5848 134.118 25.7751 134.527 26.1266L134.431 26.5478Z"
        fill="#363435"
      />
      <path
        d="M139.017 28.7427C138.171 28.7427 137.688 28.1172 137.688 27.1699C137.688 26.2226 138.196 25.5915 139.017 25.5915C139.837 25.5915 140.352 26.217 140.352 27.1699C140.352 28.1228 139.865 28.7427 139.017 28.7427ZM139.017 25.768C138.301 25.768 137.891 26.324 137.891 27.1755C137.891 28.0269 138.301 28.5565 139.017 28.5565C139.732 28.5565 140.149 28.0317 140.149 27.1755C140.149 26.3192 139.728 25.768 139.017 25.768ZM138.592 26.3491H139.022C139.406 26.3491 139.609 26.4943 139.609 26.8161C139.617 26.9265 139.585 27.036 139.519 27.1251C139.454 27.2142 139.358 27.2771 139.251 27.3026L139.594 27.9977H139.364L139.037 27.3332H138.788V27.9977H138.586L138.592 26.3491ZM138.794 26.4881V27.1998H139.022C139.073 27.2049 139.124 27.1982 139.172 27.1803C139.22 27.1625 139.263 27.1338 139.298 27.0966C139.334 27.0594 139.36 27.0147 139.375 26.9658C139.39 26.9169 139.394 26.8653 139.386 26.8147C139.386 26.5812 139.266 26.4881 139.022 26.4881H138.794Z"
        fill="#363435"
      />
      <path
        d="M11.2197 11.4491H4.67949V19.2751H0.648346V0.294006H12.1857V2.39645H4.67949V9.40508H11.2197V11.4491Z"
        fill="#8AD2D1"
      />
      <path
        d="M21.3559 0.294006L27.2552 19.2758H22.9871L21.3559 13.961H14.7532L13.0295 19.2758H10.5768L16.8848 0.294006H21.3559ZM18.1435 3.18531L15.3363 12.1212H20.7999L18.1984 3.18531H18.1435Z"
        fill="#8AD2D1"
      />
      <path
        d="M38.3764 3.33125C37.1963 2.56762 35.8376 2.12492 34.4342 2.04685C34.1117 2.01409 33.7859 2.05198 33.4795 2.15785C33.1731 2.26373 32.8934 2.43507 32.66 2.65995C32.4265 2.88484 32.2448 3.15787 32.1275 3.46008C32.0102 3.76229 31.9601 4.08641 31.9807 4.40993C31.9807 8.49806 39.6614 7.56395 39.6614 13.8713C39.6614 17.5216 36.6534 19.5948 32.9739 19.5948C28.6231 19.5948 27.2205 17.288 27.2205 17.288L28.3326 15.5943C28.8923 16.1912 29.5715 16.6636 30.3259 16.9805C31.0803 17.2975 31.8931 17.452 32.7112 17.434C34.6086 17.434 35.6025 16.3219 35.6025 14.7769C35.6025 11.0975 28.0094 11.7105 28.0094 5.66584C28.0094 2.16083 30.581 0 34.1715 0C35.82 0.0294972 37.4316 0.492882 38.8441 1.34348L38.3764 3.33125Z"
        fill="#8AD2D1"
      />
      <path
        d="M53.883 2.39645H48.8017V19.2758H44.7421V2.39645H39.6323V0.294006H53.8803L53.883 2.39645Z"
        fill="#8AD2D1"
      />
      <path
        d="M66.8779 19.2758H55.0798V0.294006H66.4692V2.39715H59.1103V8.64611H65.5935V10.6902H59.1103V17.1727H66.8779V19.2758Z"
        fill="#8AD2D1"
      />
      <path
        d="M81.5957 6.25175C81.6059 7.35566 81.3043 8.44004 80.7257 9.38022C80.1471 10.3204 79.315 11.0782 78.3249 11.5666L82.7036 19.2765H78.0608L74.4974 12.3846H72.9496V19.2765H68.9185V0.294006H74.7567C78.9914 0.294006 81.5908 2.45484 81.5908 6.25175M72.9461 2.19281V10.4566H74.4356C76.538 10.4566 77.2393 9.49265 77.2393 6.42551C77.2393 3.41745 76.4511 2.19073 74.348 2.19073L72.9461 2.19281Z"
        fill="#8AD2D1"
      />
      <path
        d="M113.279 0.294006L108.315 19.2758H104.868L100.897 5.25856H100.838L96.896 19.2758H93.391L88.3389 0.294006H92.632L96.137 14.6615H96.1947L100.079 0.294006H103.32L107.321 14.5747H107.408L110.709 0.294006H113.279Z"
        fill="#8AD2D1"
      />
      <path
        d="M121.688 0.294006L127.588 19.2758H123.324L121.688 13.961H115.085L113.362 19.2758H110.91L117.221 0.294006H121.688ZM118.476 3.18531L115.672 12.1212H121.133L118.533 3.18531H118.476Z"
        fill="#8AD2D1"
      />
      <path
        d="M133.456 8.90883L137.544 0.290527H140.348L134.653 11.5047V19.2758H130.594V11.9461L124.695 0.294002H129.222L133.398 8.9123L133.456 8.90883Z"
        fill="#8AD2D1"
      />
    </svg>
  );
};

export default IFWLogo;
