import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/routePaths";
import { Box, Button, FormControl, InputLabel, Link, OutlinedInput, Typography } from "@mui/material";
import img from "../../assets/svg/bg-image.svg";
import IFWIcon from "../../assets/svg/IFWIcon";
import FWLogoWhite from "../../assets/images/fw-logo-white.png";

const ForgotPass = () => {
  let navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleChange = (prop: keyof typeof formValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const signIn = () => {
    return navigate(ROUTE_PATHS.SIGN_IN);
  };

  const handleSubmit = () => {
    // se simula el envío de email
    console.log("enviar email");
    setIsEmailSent(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      sx={{
        backgroundImage: `url(${img})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <img src={FWLogoWhite} style={{ marginBottom: "5rem", width: "20rem" }} />
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          width: "36.8rem",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingX: "30px",
          paddingY: "40px",
          boxShadow: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: "16px",
            width: "100%",
          }}
        >
          <IFWIcon width="48px" height="48px" />
          <Typography variant="h3" sx={{ marginLeft: "18px" }}>
            CoachConnect
          </Typography>
        </Box>
        {isEmailSent ? (
          <>
            <Typography variant="body1" sx={{ textAlign: "center", marginBottom: "10px" }}>
              Email instructions to reset your password sent to {formValues.email}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ borderRadius: "8px", marginTop: "20px", width: "100%" }}
              onClick={signIn}
            >
              <Typography sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}>Sign In</Typography>
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1" sx={{ textAlign: "start", marginBottom: "10px" }}>
              Please enter your account email to reset your password.
            </Typography>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel htmlFor="forgot-input-email">Email</InputLabel>
              <OutlinedInput
                id="forgot-input-email"
                label="Email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleChange("email")}
              />
            </FormControl>
            <Button
              id="forgot-btn-submit"
              variant="contained"
              color="primary"
              size="large"
              sx={{ borderRadius: "8px", marginTop: "20px", width: "100%" }}
              onClick={handleSubmit}
            >
              <Typography sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}>Submit</Typography>
            </Button>
            <Link
              id="forgot-link-back"
              textAlign="center"
              onClick={signIn}
              sx={{ cursor: "pointer", display: "block", marginTop: "3rem" }}
            >
              Back to Sign In
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ForgotPass;
