import { flow, Instance, types, applySnapshot } from "mobx-state-tree";
import { api } from "../service/api";
import { ItemAppTrackingModel } from "../models/MyClients";
import { ContextModel } from "../models/Common";
import { IParam } from "../constants/interfaces";
import { serviceCoach } from "../utils/env";
import { formatDate } from "../utils/date";

const AppTrackingStore = types
  .model({
    items: types.optional(types.array(ItemAppTrackingModel), []),
    context: types.maybe(ContextModel),
    isLoading: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    buildUrl(id: number, param: IParam) {
      const url = `${serviceCoach}/api/v1/my-clients/app-tracking/${id}`;

      const queryParams = new URLSearchParams();

      Object.keys(param).forEach((key) => {
        const value = param[key as keyof IParam];
        if (value !== undefined && value !== null) {
          queryParams.append(key, value.toString());
        }
      });

      return `${url}?${queryParams.toString()}`;
    },
  }))
  .actions((self) => ({
    fetch: flow(function* (id: number, param: IParam) {
      try {
        self.isLoading = true;
        const url = self.buildUrl(id, param);
        const { data } = yield api.get(url);
        const { items, context } = data;

        let rowsAux: Instance<typeof ItemAppTrackingModel>[] | [] = [];
        if (items?.length && items.length > 0) {
          items?.forEach((item: Instance<typeof ItemAppTrackingModel>, index: number) => {
            let row: Instance<typeof ItemAppTrackingModel> = {
              id: 0,
              date: "",
              startsDay: 0,
              workoutDay: false,
              waterDay: 0,
              fastingDay: 0,
              foodDay: 0,
            };

            row.id = index;
            row.date = item?.date ? formatDate(item.date) : "-";
            row.startsDay = item.startsDay ?? 0;
            row.workoutDay = item.workoutDay ?? false;
            row.waterDay = item.waterDay ?? 0;
            row.fastingDay = item.fastingDay ?? 0;
            row.foodDay = item.foodDay ?? 0;

            rowsAux = [...rowsAux, row];
          });
        }
        applySnapshot(self, {
          ...self,
          items: rowsAux,
          context,
        });
      } catch (error) {
        console.log("Error fetch app tracking:", error);
      } finally {
        self.isLoading = false;
      }
    }),
    clear: function () {
      self.items.clear();
      self.context = undefined;
    },
  }));

export const appTrackingStore = AppTrackingStore.create();
