import { applySnapshot, flow, types } from "mobx-state-tree";
import { HomeModel } from "../models/Home";
import { api } from "../service/api";
import { serviceCoach } from "../utils/env";

const HomeStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
    coach: types.maybe(HomeModel),
  })
  .views((self) => ({}))
  .actions((self) => ({
    fetchHome: flow(function* () {
      try {
        self.isLoading = true;
        const { data } = yield api.get(`${serviceCoach}/api/v1/home`);

        applySnapshot(self, {
          ...self,
          coach: data,
        });
      } catch (error) {
        console.log("Error fetch home:", error);
        throw error;
      } finally {
        self.isLoading = false;
      }
    }),
    clear: function () {
      self.coach = undefined;
    },
  }));

export const homeStore = HomeStore.create();
