import React, { createContext, useContext } from "react";
import { authStore } from "./AuthStore";
import { homeStore } from "./HomeStore";
import { myLeadsStore } from "./MyLeadsStore";
import { ClientsStore } from "./MyClientsStore";
import { appTrackingStore } from "./AppTrackingStore";
import { profileStore } from "./ProfileStore";
import { foodStore } from "./FoodStore";

//Add store here, example how to call a store:
//const { authStore } = useStore();

const stores = {
  authStore,
  homeStore,
  myLeadsStore,
  ClientsStore,
  appTrackingStore,
  profileStore,
  foodStore,
};

const StoreContext = createContext(stores);

export const StoreProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);
