import { Suspense, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRoutes from "../navigation/MainRoutes";
import Spinner from "../components/Spinner/Spinner";
import { StoreProvider } from "../store/StoreContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useStore } from "../store/StoreContext";
import theme from "../theme/theme";
import "../sass/index.scss";

const App = () => {
  const { authStore, homeStore } = useStore();
  useEffect(() => {
    const getUser = async () => {
      await homeStore.fetchHome();
    };

    if (authStore.isAuthenticated()) getUser();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="coach-connect">
        <Router>
          <Suspense fallback={<Spinner sx={{ position: "absolute", top: "45%", left: "55%" }} />}>
            <StoreProvider>
              <MainRoutes />
            </StoreProvider>
          </Suspense>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
