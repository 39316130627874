import React from "react";
import MainCard from "../../components/MainCard/MainCard";
import { Box, Divider, Link, Typography } from "@mui/material";

//TODO agregar telefono y email
const Help = () => {
  return (
    <MainCard alignItems="center">
      <Box alignItems="center" display="flex" flexDirection="column" width={"100%"} id="help-text">
        <Typography variant="h3" marginBottom={"16px"} id="help-heading">
          Help
        </Typography>
        <Divider sx={{ width: "100%", marginBottom: "16px" }} />
        <Typography variant="body1">Need more help?</Typography>
        <Typography variant="body1">Contact our Coach Support team at</Typography>
        <Typography variant="body1">
          <Link sx={{ cursor: "pointer", marginRight: "5px" }} underline="none">
            (727) 677-9003
          </Link>
          or
          <Link
            href="mailto:coachsupport@fasterwaytofatloss.com"
            underline="none"
            sx={{ cursor: "pointer", marginLeft: "5px" }}
          >
            coachsupport@fasterwaytofatloss.com
          </Link>
        </Typography>
      </Box>
    </MainCard>
  );
};

export default Help;
