import { useEffect, useState } from "react";
import Card, { CardColor } from "../../components/Card/Card";
import { useNavigate } from "react-router-dom";
import MainCard from "../../components/MainCard/MainCard";
import { colors } from "../../constants/colors";
import "./styles.scss";
import { homeStore } from "../../store/HomeStore";
import Spinner from "../../components/Spinner/Spinner";
import { ROUTE_PATHS } from "../../constants/routePaths";
import { Box, Link, Typography } from "@mui/material";

interface Card {
  title: string;
  primaryText: string | number;
  secondaryText: string;
  path: string;
  color: CardColor;
  id: string;
}

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  let navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  const cardsData: Card[] = [
    {
      title: "My Leads",
      primaryText: homeStore.coach?.countLeads || "0",
      secondaryText: "this week",
      path: ROUTE_PATHS.MY_LEADS,
      color: "primary",
      id: "home-card-my-leads",
    },
    {
      title: "My Clients",
      primaryText: homeStore.coach?.countClients || "0",
      secondaryText: "total active",
      path: ROUTE_PATHS.MY_CLIENTS,
      color: "primary",
      id: "home-card-my-clients",
    },
    {
      title: "Energy Chart",
      primaryText: "SELL",
      secondaryText: "marketing strategy",
      path: ROUTE_PATHS.ENERGY_CHART,
      color: "primary",
      id: "home-card-energy-chart",
    },
    {
      title: "Back Office",
      primaryText: "EARN",
      secondaryText: isMobile ? "comissions" : "commissions and payments",
      path: ROUTE_PATHS.PAP,
      color: "secondary",
      id: "home-card-pap",
    },
    {
      title: isMobile ? "Training" : "Training Portal", 
      primaryText: "LEARN",
      secondaryText: "program information",
      path: ROUTE_PATHS.TRAINING_PORTAL,
      color: "secondary",
      id: "home-card-training-portal",
    },
  ];

  useEffect(() => {
    const fetchHome = async () => {
      await homeStore.fetchHome();
      setLoading(false);
    };

    fetchHome();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, []);

  return (
    <MainCard backgroundColor={colors.background.main}>
      {loading ? (
        <Spinner size={50} sx={{ position: "absolute", top: "45%", left: "55%" }} />
      ) : (
        <div className="home">
          {cardsData.map((card, index) => (
            <Card
              className="card"
              id={card.id}
              key={index}
              title={card.title}
              primaryText={card.primaryText}
              secondaryText={card.secondaryText}
              onClick={() => handleClick(card.path)}
              color={card.color}
            />
          ))}
        </div>
      )}

      <Box sx={{ mt: "auto", textAlign: "left" }}>
        <Typography variant="body1" sx={{ color: "#333" }}>
          Have new ideas or suggestions for Coach Connect? Email{" "}
          <Link href="mailto:cc_feedback@fasterwaytofatloss.com" sx={{ color: colors.blue.two, fontWeight: "bold" }}>
            cc_feedback@fasterwaytofatloss.com
          </Link>
        </Typography>
      </Box>
    </MainCard>
  );
};

export default Home;
