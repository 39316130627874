import { types } from "mobx-state-tree";

export const SearchModel = types.model("SearchModel", {
  label: types.string,
  value: types.string,
});

export const ContextModel = types.model("ContextModel", {
  page: types.number,
  pages: types.number,
  size: types.number,
  totalRows: types.optional(types.number, 0),
});
