import IiconProps from "../../constants/iconProp";

const IFWIcon = (props: IiconProps) => {
  const { width = "33", height = "33" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.4536 27.4894C36.3513 18.4584 31.9736 3.26299 19.6315 0.591906C6.26725 -2.31029 -4.50149 11.414 2.07175 23.7294C4.6406 28.5961 9.53387 31.8005 15.0227 32.2094V20.6984H20.7915V18.8851H15.0227V12.6051H21.6359V10.7207H11.4672V27.1516C2.31175 22.765 2.20508 10.0318 10.0894 5.12964C14.4582 2.45855 19.9782 2.56077 24.2492 5.38741C35.2135 12.8896 30.5469 29.7961 18.5471 31.8494V32.2271C21.867 31.8094 25.147 29.9161 27.4536 27.4894Z"
        fill="#8AD2D1"
      />
    </svg>
  );
};

export default IFWIcon;
