import { DateTime } from "luxon";

export const formatDate = (dateString: any) => {
  if (dateString && dateString !== "-" && dateString !== "0001-01-01T00:00:00Z") {
    const [datePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    return `${month}/${day}/${year.slice(-2)}`;
  } else {
    return "-";
  }
};

export const formatMinutesToHours = (minutes: number): string => {
  if (isNaN(minutes)) return "0:00";

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = remainingMinutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

export const convertDateFormat = (dateString: string): string => {
  const date = DateTime.fromFormat(dateString, "MM/dd/yy");

  return date.toFormat("yyyy-MM-dd");
};
